import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { CommonData } from '../../../../common';
import Asterisk from '../../../Asterisk';
import InputGroup from 'react-bootstrap/InputGroup';

const ProjectDeadline = ({ shouldShowProjectType, formData, setFormData, invalidFieldStyle, showError }) => {

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.id]: event.target.value
        });
    }

    /**
     * Смена типа проекта
     * Пришлось вынести отдельно от handleChange
     * @date 2021-10-12
     * @returns {void}
     */
    const handleChangeTypes = (event) => {
        setFormData({
            ...formData,
            projectType: event
        });
    }

    return (
        <div className="mt-3">
            <Row>
                <Col>
                    <h5>Сроки проекта <Asterisk /> </h5>
                </Col>
            </Row>

            <Row className="align-items-center">
                <Col md={3} xs={12}>
                    <InputGroup>
                        <Form.Control 
                            onChange={handleChange} 
                            value={formData.daysProject} 
                            id="daysProject" 
                            placeholder="Сроки проекта"
                            style={invalidFieldStyle}
                        />
                        <InputGroup.Text>дней <Asterisk /></InputGroup.Text>
                    </InputGroup>
                    {showError && 
                        <Form.Text className="text-danger">
                            Обязательное поле
                        </Form.Text>
                    }
                </Col>
                <Col md={2} xs={12} className="mt-md-0 mt-3">
                    <Form.Select onChange={handleChange} value={formData.daysType} id="daysType">
                        {Object.keys(CommonData.daysTypes).map((typeId) => (
                            <option key={typeId} value={typeId}>{CommonData.daysTypes[typeId]}</option>
                        ))}
                    </Form.Select>
                </Col>

                {shouldShowProjectType ?

                <Col md={2} xs={12}>
                    <ToggleButtonGroup type="radio" name="project_type" value={formData.projectType} onChange={handleChangeTypes}>
                        {Object.keys(CommonData.projectTypes).map((type) => (
                             <ToggleButton
                                key={type}
                                type="radio"
                                value={type}
                                id={'type-'+type}
                                checked={formData.projectType == type}
                            >
                             {CommonData.projectTypes[type]}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Col>
                : null}
            </Row>
        </div>
    );
}

export default ProjectDeadline;
