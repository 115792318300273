import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { Typeahead } from 'react-bootstrap-typeahead';
import ProjectDeadline from '../ProjectDeadlines/ProjectDeadlines';
import ManagersSimple from '../Managers/ManagersSimple';
import { CommonData, formTypes } from '../../../../common';
import Button from 'react-bootstrap/Button'
import { addPayment, copyPayment, editPayment, loadPayments } from '../../../../reducers/payment-reducer';
import { AjaxProvider } from '../../../../api/AjaxProvider';
import ManagersAdmin from '../Managers/ManagersAdmin';
import AdminFormControls from '../AdminFormControls';
import Alert from 'react-bootstrap/Alert'
// styles
import "../../../../assets/style.css";
// asterisk mark
import Asterisk from '../../../Asterisk';

const IncomeForm = (props) => {
    const dispatch = useDispatch();
    const checkedIncomeType = useSelector((state) => state.payment.checkedIncomeType);
    const isAdmin = useSelector((state) => state.auth.isAdmin);
    const [loading, setLoading] = useState(false);
    const addPaymentError = useSelector((state) => state.payment.addPaymentError);
    const siteCategories = useSelector((state) => state.payment.siteCategories);
    const paymentList = useSelector((state) => state.payment.payments);
    // TODO
    // сейчас здесь закреплено наличие/отсутствие данных daysProject. Если нет - значит платеж создавался в категории "Есть в планфиксе"
    const [isPaymentSentToPlanfix, setPaymentToPlanfix] = useState(false);
    const [attemptedSubmit, setAttemptedSubmit] = useState(false);

    // Проверяем отправлен ли платеж из списка в ПФ    
    useEffect(() => {
        if(paymentList.length && props.currentIndex !== undefined && paymentList[props.currentIndex]?.daysProject === null) setPaymentToPlanfix(true);
        else setPaymentToPlanfix(false);
    }, [paymentList, props.currentIndex])

    const handleChange = (event) => {
        let value = event.target.value;
        
        // Обработка полей с суммами
        if (['sum', 'full_sum', 'consumptions'].includes(event.target.id)) {
            // Удаляем все нечисловые символы, кроме точки и запятой
            value = value.replace(/[^\d.,]/g, '');
            // Заменяем запятую на точку
            value = value.replace(',', '.');
            // Если несколько точек - оставляем только первую
            const dots = value.match(/\./g);
            if (dots && dots.length > 1) {
                const parts = value.split('.');
                value = parts[0] + '.' + parts.slice(1).join('');
            }
            
            // Проверяем, является ли значение незавершенным числом (например "123.")
            if (value === '' || value === '.' || /^\d+\.$/.test(value)) {
                // Оставляем как есть для продолжения ввода
            } else {
                // Преобразуем в число только если это завершенное число
                const floatValue = parseFloat(value);
                if (!isNaN(floatValue)) {
                    value = floatValue.toString();
                }
            }
        }

        if (event.target.id === 'payer' && !!siteCategories) {
            const siteName = Object.keys(siteCategories).filter(item => item.toLowerCase() == value.toLowerCase())[0];
            const categoryValue = typeof siteName != 'undefined' ? siteCategories[siteName][0].toString() : '';
            if (typeof categoryValue == 'undefined' || categoryValue === '') {
                props.setFormData({
                    ...props.formData,
                    [event.target.id]: value
                });
                return;
            }

            let category = categoryValue[0].toUpperCase() + categoryValue.slice(1).toString().toLowerCase();

            Object.keys(CommonData.categories).forEach(function(categoryType) {
                CommonData.categories[categoryType].forEach(function(categoryItem) {
                    if (categoryItem.toLowerCase() == categoryValue.toLowerCase()) {
                        category = categoryItem;
                        
                    }
                })
            })
            props.setFormData(prev => ({
                ...prev,
                category: category
            }));
        }

        if(event.target.id === "site") {
            props.setFormData(prev => ({
                ...prev,
                "project": "",
            }))
        }
        
        props.setFormData(prev => ({
            ...prev,
            [event.target.id]: value,
        }));
    }
    /**
     * Хук
     * При смене категории, если выбран тип месячный, то подставляем название проекта
     */
    useEffect(() => {
        if (checkedIncomeType === formTypes.INCOME_MONTHLY && props.formData.category !== '0') {
            switch (props.formData.category) {
                case 'Разработка':
                    props.setFormData({
                        ...props.formData,
                        project: 'Абонемент '+props.formData.period
                    });
                    break;
                case 'Абонемент':
                    props.setFormData({
                        ...props.formData,
                        project: 'Абонемент '+props.formData.period
                    });
                    break;
                case 'Продвижение':
                    props.setFormData({
                        ...props.formData,
                        project: 'seo '+props.formData.period
                    });
                    break;
                case 'Контекст':
                    props.setFormData({
                        ...props.formData,
                        project: 'cpc '+props.formData.period
                    });
                    break;
                default:
                    break;
            }
        }
    }, [props.formData.category, props.formData.period]);


    /**
     * Нужно ли показывать переключатель типа проекта
     * @date 2021-10-12
     * @returns {boolean}
     */
     const shouldShowProjectType = () => {
        let shouldShowProjectType = false;
        Object.keys(CommonData.categories).forEach((categoryType) => {
          const isCategoryIncludes  = CommonData.categories[categoryType].includes(props.formData.category);
          if (isCategoryIncludes && categoryType === 'Разработка') {
            shouldShowProjectType = true;
          }
        });

        return shouldShowProjectType;
    }

    /**
    * TODO как-то лучше организовать валидацию
    * @date 2021-10-12
    * @returns {boolean}
    */
    const isFormValid = () => {
        if (checkedIncomeType === formTypes.INCOME_NEW) {
            if (props.formData.category == 0) return false;
            if (!props.formData.sum || props.formData.sum < 0) return false;
            if (!props.formData.payer) return false;
            if (!props.formData.site) return false;
            if (!props.formData.project) return false;

            if(!isPaymentSentToPlanfix) {
                if (!props.formData.daysProject) return false;
                if (!props.formData.full_sum || props.formData.full_sum < 0) return false;
            }
        }

        if (checkedIncomeType === formTypes.INCOME_EXISTS) {
            if (props.formData.category == 0) return false;
            if (!props.formData.sum || props.formData.sum < 0) return false;
            if (!props.formData.payer) return false;
            if (!props.formData.site) return false;
            if (!props.formData.project) return false;
        }

        return true;
    }

    // Функция для проверки валидности конкретного поля
    const isFieldValid = (fieldName) => {
        if (!attemptedSubmit) return true;
        
        switch (fieldName) {
            case 'sum':
                return props.formData.sum && parseFloat(props.formData.sum) > 0;
            case 'consumptions':
                if (!props.formData.consumptions) return true; // если поле пустое - оно валидно
                if (!props.formData.sum) return false; // если нет основной суммы - поле невалидно
                return parseFloat(props.formData.consumptions) <= parseFloat(props.formData.sum);
            case 'payer':
                return !!props.formData.payer;
            case 'site':
                return !!props.formData.site;
            case 'project':
                return !!props.formData.project;
            case 'category':
                return props.formData.category !== '0';
            case 'daysProject':
                if (isPaymentSentToPlanfix) return true;
                return checkedIncomeType === formTypes.INCOME_NEW ? !!props.formData.daysProject : true;
            case 'full_sum':
                if (isPaymentSentToPlanfix) return true;
                if (checkedIncomeType !== formTypes.INCOME_NEW) return true;
                if (!props.formData.full_sum || parseFloat(props.formData.full_sum) <= 0) return false;
                if (!props.formData.sum) return false;
                return parseFloat(props.formData.full_sum) >= parseFloat(props.formData.sum);
            default:
                return true;
        }
    };

    /**
     * Добавление платежа
     */
    const addNewPayment = async () => {
        setAttemptedSubmit(true);
        if (!isFormValid()) return;
        
        setLoading(true);
        await dispatch(addPayment(props.formData));
        setLoading(false);
    };

    /**
     * Получение данных из амо срм
     */
    const getAmoData = async () => {
        const data = {
            link: props.formData.amo
        };
        const response = await AjaxProvider.post('http://admin.leadget.ru/bux/apiv2/AmoProject.php', data);

        if (response.success) {
            props.setFormData({
                ...props.formData,
                payer: response.result.payer,
                total: response.result.total,
                site: response.result.payer,
                project: response.result.name
            });
        } else {
            alert(response.errors);
        }
    }

    const editSelectedPayment = () => {
        setAttemptedSubmit(true);
        if (!isFormValid()) return;
        
        if (!props.modal) return false;
        if (typeof(props.currentIndex) == 'undefined') return false;
        const data = {
            formData: props.formData,
            index: props.currentIndex
        }
        dispatch(editPayment(data));
    }

    /**
     * Получение строки "кто платит"
     * @date 2021-10-12
     * @returns {any}
     */
    const getPayerField = () => {
        return (
            <Typeahead
                onChange={(selected) => {
                    if (!selected[0]) return false;
                    const event = {
                        target: {
                            id: 'payer',
                            value: selected[0]
                        }
                    }
                    handleChange(event);
                }}
                onInputChange={(text, event) => {
                    event.target.id = 'payer';
                    event.target.value = text;
                    return handleChange(event);
                }}
                id="payer"
                selected={props.formData.payer ? [props.formData.payer] : []}
                options={props.contragents}
            />
        );
    }

    /**
     * Получение строки "Сайт"
     * @date 2021-10-12
     * @returns {any}
     */
    const getSiteField = () => {
        if (checkedIncomeType === formTypes.INCOME_NEW || checkedIncomeType === formTypes.INCOME_MONTHLY) {
                return (
                    <Typeahead
                        onChange={(selected) => {
                            if (!selected[0]) return false;
                            const event = {
                                target: {
                                    id: 'site',
                                    value: selected[0]
                                }
                            }
                            handleChange(event);
                        }}
                        onInputChange={(text, event) => {
                            event.target.id = 'site';
                            event.target.value = text;
                            handleChange(event);
                        }}
                        id="site"
                        selected={props.formData.site ? [props.formData.site] : []}
                        options={!!props.sites ? Object.keys(props.sites) : []}
                    />
                );
        }
        return (
            <Form.Select value={props.formData.site} onChange={handleChange} id="site">
                <option value="">Не выбрано</option>
                {Object.keys(props.sites).map((site) => (
                    <option key={site} value={site}>{site}</option>
                ))}
            </Form.Select>
        );
    }

    /**
     * Получение строки "Проект"
     * @date 2021-10-12
     * @returns {any}
     */
    const getProjectField = () => {
        if (checkedIncomeType === formTypes.INCOME_NEW || checkedIncomeType === formTypes.INCOME_MONTHLY) {
                return (
                    <Typeahead
                        onChange={(selected) => {
                            if (!selected[0]) return false;
                            const event = {
                                target: {
                                    id: 'project',
                                    value: Object.keys(props.sites[props.formData.site]).find((id) => props.sites[props.formData.site][id] === selected[0]),
                                }
                            }
                            handleChange(event);
                        }}
                        onInputChange={(text, event) => {
                            event.target.id = 'project';
                            event.target.value = text;
                            handleChange(event);
                        }}
                        id="project"
                        // selected={props.formData.project ? [props.formData.project] : []}
                        // options={props.sites[props.formData.site] ? props.sites[props.formData.site] : []}
                        // options={props?.sites[props.formData.site] ? Object.values(props?.sites[props.formData.site]) : []}
                        selected={getSelectedProjectValue()}
                        options={props.sites[props.formData.site] ? Object.values(props.sites[props.formData.site]) : []}
                    />
                );
        }
        return (
            <InputGroup>
                <Form.Select value={props.formData.project} onChange={handleChange} id="project">
                    <option value="">Не выбрано</option>
                    {props?.sites[props.formData.site] ?
                    // Object.values(props?.sites[props.formData.site]).map(site => (
                    //     <option key={site} value={site}>{site}</option>
                    // )) : null
                    Object.keys(props.sites[props.formData.site]).map((projectId) => (
                        <option key={projectId} value={projectId}>{props.sites[props.formData.site][projectId]}</option>
                    ))
                    : null}
                </Form.Select>
                {/* <ProjectUpdateButton site={props.formData.site} /> */}
            </InputGroup>
        );
    }

    const getSelectedProjectValue = () => {
        if (!props.sites[props.formData.site]) {
            return []
        }

        if (typeof props.sites[props.formData.site][props.formData.project] !== 'undefined') {
            return [props.sites[props.formData.site][props.formData.project]]
        }

        const id = Object.keys(props.sites[props.formData.site]).find((id) => props.sites[props.formData.site][id] === props.formData.project)

        if (id) {
            return [props.sites[props.formData.site][id]]
        }

        return [];
    }

    // Стили для невалидных полей
    const invalidFieldStyle = {
        border: '1px solid #dc3545',
        boxShadow: '0 0 0 0.2rem rgba(220, 53, 69, 0.25)'
    };

    return (
        <Form>
            <Row className="mt-3">
                <Col md={4} xs={12}>
                    <InputGroup>
                        <Form.Control 
                            onChange={handleChange} 
                            required 
                            value={props.formData.sum} 
                            id="sum" 
                            placeholder="Ожидаем сумму"
                            style={!isFieldValid('sum') ? invalidFieldStyle : {}}
                        />
                        <InputGroup.Text>₽</InputGroup.Text>
                    </InputGroup>
                    {!isFieldValid('sum') && 
                        <Form.Text className="text-danger">
                            Обязательное поле. Введите сумму больше 0
                        </Form.Text>
                    }
                </Col>
                <Col md={4} xs={12}>
                    <InputGroup>
                        <Form.Control 
                            onChange={handleChange} 
                            value={props.formData.consumptions} 
                            id="consumptions" 
                            placeholder="Дополнительные затраты"
                            style={!isFieldValid('consumptions') ? invalidFieldStyle : {}}
                        />
                        <InputGroup.Text>₽</InputGroup.Text>
                    </InputGroup>
                    <Form.Label>Затраты на шаблон, подрядчиков, лицензии </Form.Label>
                    {!isFieldValid('consumptions') && 
                        <Form.Text className="text-danger">
                            Дополнительные затраты должны быть меньше ожидаемой суммы
                        </Form.Text>
                    }
                </Col>
            </Row>

            <Row className="mt-3">
                <Col md={4} xs={12}>
                    <Form.Group>
                        <Form.Label>Кто платит <Asterisk /></Form.Label>
                        <div style={!isFieldValid('payer') ? invalidFieldStyle : {}}>
                            {getPayerField()}
                        </div>
                        {!isFieldValid('payer') && 
                            <Form.Text className="text-danger">
                                Обязательное поле
                            </Form.Text>
                        }
                    </Form.Group>
                </Col>
                <Col md={4} xs={12}>
                    <Form.Label>Сайт <Asterisk /></Form.Label>
                    <div style={!isFieldValid('site') ? invalidFieldStyle : {}}>
                        {getSiteField()}
                    </div>
                    {!isFieldValid('site') && 
                        <Form.Text className="text-danger">
                            Обязательное поле
                        </Form.Text>
                    }
                </Col>
            </Row>

            <Row className="mt-3">
                <Col md={4} xs={12}>
                    <Form.Label>Категория <Asterisk /></Form.Label>
                    <div style={!isFieldValid('category') ? invalidFieldStyle : {}}>
                        <Form.Select value={props.formData.category} onChange={handleChange} id="category">
                            <option value="0">Не выбрано</option>
                            {Object.keys(CommonData.categories).map((category) => (
                                <optgroup label={category} key={category}>
                                    {CommonData.categories[category].map((subcat) => (
                                        <option key={subcat} value={subcat}>{subcat}</option>
                                    ))}
                                </optgroup>
                            ))}
                        </Form.Select>
                    </div>
                    {!isFieldValid('category') && 
                        <Form.Text className="text-danger">
                            Выберите категорию
                        </Form.Text>
                    }
                </Col>
                <Col md={4} xs={12}>
                    <Form.Label>Проект <Asterisk /></Form.Label>
                    <div style={!isFieldValid('project') ? invalidFieldStyle : {}}>
                        { getProjectField() }
                    </div>
                    {!isFieldValid('project') && 
                        <Form.Text className="text-danger">
                            Обязательное поле
                        </Form.Text>
                    }
                </Col>
            </Row>

            <Row className="mt-3">
                <Col md={8} xs={12}>
                    <Form.Label>Комментарий</Form.Label>
                    <Form.Control value={props.formData.comment} onChange={handleChange} id="comment" placeholder="Детали платежа или проекта" />
                </Col>
            </Row>

            <Row className="mt-5">
                <Col>
                    <h4 className="pb-3 mb-4 border-bottom">Информация по проекту</h4>
                </Col>
            </Row>

            {!isPaymentSentToPlanfix && checkedIncomeType === formTypes.INCOME_NEW ?
                <ProjectDeadline 
                    shouldShowProjectType={shouldShowProjectType() ? true : false} 
                    formData={props.formData} 
                    setFormData={props.setFormData}
                    invalidFieldStyle={!isFieldValid('daysProject') ? invalidFieldStyle : {}}
                    showError={!isFieldValid('daysProject')}
                />
            : null}

            {isAdmin ?
                <ManagersAdmin  managers={props.managers} formData={props.formData} setFormData={props.setFormData}/>
            :
                <ManagersSimple managers={props.managers} formData={props.formData} setFormData={props.setFormData} />
            }

            {!isPaymentSentToPlanfix && checkedIncomeType === formTypes.INCOME_NEW ?
                <Col md={3} xs={12} className="mt-3">
                    <InputGroup>
                        <Form.Control 
                            onChange={handleChange} 
                            value={props.formData.full_sum} 
                            id="full_sum" 
                            placeholder="Полная сумма проекта"
                            style={!isFieldValid('full_sum') ? invalidFieldStyle : {}}
                        />
                        <InputGroup.Text>₽ <Asterisk /> </InputGroup.Text>
                    </InputGroup>
                    {!isFieldValid('full_sum') && 
                        <Form.Text className="text-danger">
                            {!props.formData.full_sum || parseFloat(props.formData.full_sum) <= 0 
                                ? 'Обязательное поле. Введите сумму больше 0'
                                : 'Полная сумма проекта должна быть больше или равна ожидаемой сумме'}
                        </Form.Text>
                    }
                </Col>
            : null}

            <Row className="mt-3">
                <Col>
                    <Button 
                        variant="primary" 
                        size="lg" 
                        onClick={() => {
                            setAttemptedSubmit(true);
                            props.modal ? editSelectedPayment() : addNewPayment();
                        }}
                        disabled={loading}
                    >
                        {props.modal ? 'Изменить' : '+ Добавить'}
                    </Button>
                </Col>
            </Row>

            { isAdmin ?
                <Row className="mt-3">
                    <AdminFormControls formData={props.formData} />
                </Row>
            : null }

            {addPaymentError && !props.modal ?
                <Alert className="mt-2" variant={'danger'}>
                    {addPaymentError}
                </Alert>
            : null}
        </Form>
    );
}

export default IncomeForm;